import React, { Component } from "react"
import styled from "styled-components"
import titleCase from "titlecase"

import ContentContainer from "src/containers/ContentContainer"
import PageTitle from "../../components/PageTitle"
import BackLink from "../../components/BackLink"
import List from "../../components/List"
import ListItem from "../../components/ListItem"
import Footer from "../../components/Footer"

import { categoryStorePath } from "src/utils/paths"
import * as assetHelper from "src/utils/assetHelper"

import { Store } from "src/gatsby-node/data/dataSchemaV1"
import SEO from "../../components/SEO"
import { contentMarginsCss } from "src/styles/styleConstants"
import { Category } from "src/types"

export interface StoresProps {
  stores: {
    names: Array<string>
    byName: {
      [id: string]: Store
    }
  }
  category: Category
  storeFocused: string
  storeHovered: string
  setHovered: Function
  setImplicitCategory: Function
}

class Stores extends Component<StoresProps> {
  componentDidMount = () => {
    this.props.setImplicitCategory(this.props.category)
  }

  componentWillUnmount = () => {
    // Clear any active mouse hover on transition
    this.props.setHovered(null)
  }

  render() {
    const categoryTitle = titleCase(this.props.category.name)

    // Ensure that store names are sorted correctly
    const storeNames = [...this.props.category.stores].sort((a, b) =>
      a.localeCompare(b, undefined, { sensitivity: "base" })
    )

    return (
      <>
        <SEO title={categoryTitle} keywords={[this.props.category.name]} />

        <ContentContainer>
          {/* This div is needed for sticky to work on Safari */}
          <div>
            <BackLink link="/" text="Back" />

            <TitleArea>
              <PageTitle title={categoryTitle} />
              {this.props.category.description && (
                <Description>{this.props.category.description}</Description>
              )}
            </TitleArea>

            <List>
              {storeNames.map((storeName) => {
                const store = this.props.stores.byName[storeName]
                const category = this.props.category.name || ""
                return (
                  <ListItem
                    key={storeName}
                    id={storeName}
                    text={storeName}
                    link={categoryStorePath(category, storeName)}
                    iconUrl={assetHelper.iconAssetUrlForTags(store.tags || [])}
                    category={store.tags?.[0]}
                    hovered={store.name === this.props.storeHovered}
                    setHovered={this.setHovered}
                  />
                )
              })}
            </List>
          </div>
        </ContentContainer>

        <Footer />
      </>
    )
  }

  setHovered = (id?: string) => {
    this.props.setHovered(id)
  }
}

export default Stores

const TitleArea = styled.div`
  ${contentMarginsCss}
  padding-top: 20px;
  padding-bottom: 20px;
`

const Description = styled.div`
  font-size: 15px;
  line-height: 1.3;
  margin-top: 10px;
`
