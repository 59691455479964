import { connect } from "react-redux"
import { Category } from "src/types"

import { setImplicitCategory, setHovered } from "../../actions/storesActions"

import Stores from "./Stores"

const mapStateToProps = (state, { pageContext }) => ({
  ...state.stores,
  ...state.screen,
  ...pageContext,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setImplicitCategory: (category?: Category) => {
      dispatch(setImplicitCategory(category))
    },
    setHovered: (id?: string) => {
      dispatch(setHovered(id))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stores)
